import React from "react";
import styled from "styled-components";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import isEqual from "lodash.isequal";
import { LineHeader, Title, Semi } from "components/Module/Module";
import { PadSection } from "components/Section/Section";
import Grid from "components/Grid/Grid";
import { SortButton } from "components/SortButton/SortButton";
import { OrganizationItem, OrganizationWrapper } from "modules/Organization/Organization";
import GraphQLErrorList from "components/graphql-error-list";
import SEO from "components/seo";
import Layout from "containers/layout"
import { SiteContext } from "context/site-context";
import useFilter from "utils/useFilter";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";

const ContentCol = styled.div`
  grid-column: 1 / span 6;

  ${media.large` 
    grid-column: 4 / span 9;
  `}
`;

const CustomGrid = styled(Grid)`
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  ${media.large` 
    width: 100%;
    margin-left: 0;
    transform: translateX(0);
  `}
`;

const SortGrid = styled(Grid)`
  width: 100%;
`;

const MobileSelectEl = styled.select`
  ${fontstack.secondary}
  ${type("subheaderD")}
  text-transform: uppercase;
  color: var(--brand-grey2);
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  text-decoration: underline;
  text-align: right;

  ${media.large` 
    display: none;
  `}
`;

const QuarterCol = styled.div`
  grid-column: span 6;
  display: flex;
  justify-content: space-between;

  ${media.large` 
    grid-column: span 3;
    display: block;
  `}
`;

const QuarterColDesktop = styled(QuarterCol)`
  display: none;
  ${media.large` 
    display: block;
  `}
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;
`;

export async function getServerData({ query, ...rest }) {
  const sanityClient = require("@sanity/client");
  const { getSortResults } = require("../utils/filter");

  const client = sanityClient({
    projectId: "72ij7d0h",
    dataset: "production",
    apiVersion: "2021-10-21", // use current UTC date - see "specifying API version"!
    token: `${process.env.GATSBY_SANITY_READ_TOKEN || ""}`, // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  });

  const targetQuery = `*[_type == 'organization' && _id == $id] | order(lower(name) asc) { name,
      description,
      subTitle,
      roles,
      locations,
      contactList,
      accreditations,
      _id
    }
    `;
  const params = { id: query.id };

  let target = null;
  let data = null;

  data = await getSortResults({
    query,
  });

  if (query && query.id) { 
    target = await client.fetch(targetQuery, params);
  }

  return {
    props: {
      query,
      data,
      target,
    },
  };
}

const DirectoryPage = (props) => {
  const { data, serverData, errors } = props;
  const queryParams = serverData.query;
  const [sort, setSort] = React.useState(queryParams);
  const initialSort = React.useMemo(() => queryParams, []);
  const PostRefArray = React.useRef([]);
  const isFirstQuery = React.useRef(true);
  const [ Active, setActive ] = React.useState(null);
  const [ ActiveSort, setActiveSort ] = React.useState(initialSort.field || null);
  const [ SortDirection, setSortDirection ] = React.useState(initialSort.sort || "asc");
  const { setSmoothCancel } = React.useContext(SiteContext);

  const { posts, isFetching } = useFilter(
    sort,
    serverData.data,
    false,
    initialSort,
    "directory"
  );

  React.useEffect(() => {
    if (posts && serverData.target) {
      posts.forEach((node, i) => {
        if (node._id === serverData.target[0]._id) {
          setSmoothCancel(true);
          setTimeout(() => {
            
            setActive(i);
            const yPos = PostRefArray.current[i].getBoundingClientRect().y;
            window.scrollTo(0,yPos - 300);
            isFirstQuery.current = false;

            setTimeout(() => {
              setSmoothCancel(false)
            }, 500);
          }, 1000)
          
        }
      });
    }
  }, [posts]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const toggleSort = (field) => {

    let direction = SortDirection === "asc" ? "desc" : "asc";

    if (ActiveSort === field) {
      setSortDirection(direction);
    } else {
      direction = "asc";
      setActiveSort(field);
      setSortDirection(direction);
    }

    setSort((filters) => ({ ...filters, field: field, sort: direction }));
  };

  const toggleSortMobile = (e) => {
    const field = e.target.value;
    const direction = "asc";
    setSortDirection(direction);
    setActiveSort(field);
    setSort((filters) => ({ ...filters, field: field, sort: direction }));
  };

  return (
    <Layout
      pageElements={
        <CustomLineHeader>
          <SortGrid>
            <QuarterCol>
              <Title>THE NEW MOON <Semi>DIRECTORY</Semi></Title>
              <MobileSelectEl onChange={toggleSortMobile}>
                <option value="default" disabled selected={!ActiveSort ? true : false}>sort by</option>
                <option value="name" selected={ActiveSort === "name" || false}>
                  sort by: name
                </option>
                <option
                  value="category"
                  selected={ActiveSort === "category" || false}
                >
                  sort by: category
                </option>
                <option
                  value="location"
                  selected={ActiveSort === "location" || false}
                >
                  sort by: location
                </option>
              </MobileSelectEl>
            </QuarterCol>
            <QuarterColDesktop>
              <SortButton
                onClick={() => toggleSort("name")}
                direction={ActiveSort === "name" ? SortDirection : "asc"}
                active={ActiveSort === "name" || false}
              >
                Name
              </SortButton>
            </QuarterColDesktop>
            <QuarterColDesktop>
              <SortButton
                onClick={() => toggleSort("category")}
                direction={ActiveSort === "category" ? SortDirection : "asc"}
                active={ActiveSort === "category" || false}
              >
                Category
              </SortButton>
            </QuarterColDesktop>
            <QuarterColDesktop>
              <SortButton
                onClick={() => toggleSort("location")}
                direction={ActiveSort === "location" ? SortDirection : "asc"}
                active={ActiveSort === "location" || false}
              >
                Location
              </SortButton>
            </QuarterColDesktop>
          </SortGrid>
        </CustomLineHeader>
      }
    >
      <SEO />
      <PadSection>
        <CustomGrid>
          <ContentCol>
            <OrganizationWrapper active={Active || null}>
              {posts &&
                posts.map((node,i) => (
                  <OrganizationItem
                    key={node._id}
                    node={node}
                    ref={(ref) => (PostRefArray.current[i] = ref)}
                  />
                ))}
            </OrganizationWrapper>
          </ContentCol>
        </CustomGrid>
      </PadSection>
    </Layout>
  );
};

export default DirectoryPage;
