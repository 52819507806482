import React from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 40px;
`;

const SubHeader = styled.h3`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey);
  text-transform: uppercase;
  margin: 20px 0 10px;

  ${media.large`
    ${type("subheaderB")}
    margin: 0 0 25px;
  `}
`;

const ListWrapper = styled.ol`
  margin: 0;
  padding: 0;
  counter-reset: item;
  list-style-type: none;
`;

export const NumberedList = ({ label, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {label && <SubHeader>{label}</SubHeader>}
      <ListWrapper>{children}</ListWrapper>
    </Wrapper>
  );
};

const NumberedItemWrapper = styled.li`
  position: relative;
  display: flex;
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);

  ${media.large`
    ${type("subheaderB")}
  `}

  &:not(:last-child) {
    margin: 0 0 11px;

    ${media.large`
      margin: 0 0 22px;
    `}
  }

  &:before {
    content: counter(item) " ";
    counter-increment: item;
    width: 13px;
    display: block;
    vertical-align: super;
    font-size: 0.8em;
  }
`;

export const NumberedItem = ({ children }) => {
  return <NumberedItemWrapper>{children}</NumberedItemWrapper>;
};
