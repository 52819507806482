import React, { useState, useEffect } from "react";

const OrganizationWrapper = ({ children, active }) => {
  const [ Active, setActive ] = useState(active); 

  useEffect(() => {
    setActive(active);
  }, [active]);

  const toggleEvent = (activeIndex) => {
    setActive(activeIndex);
  };

  const items = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        id: index,
        toggleEvent: toggleEvent,
        active: Active === index ? true : false,
      });
    }
    return child;
  });

  return <>{items}</>;
};

export default OrganizationWrapper;
