import React, { useState } from "react";
import styled from "styled-components";
import { LazyBlurImage } from "@manualengineering/react-lazyblur";
import Grid from "components/Grid/Grid";
import PortableText from "components/portableText";
import { NumberedList, NumberedItem } from "components/NumberedList/NumberedList";
import CloseBtn from "components/CloseBtn/CloseBtn";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const HeadWrapper = styled.div`
  box-sizing: border-box;
  padding: 19px 0 11px;
  cursor: pointer;
  user-select: none;
  position: relative;

  ${media.large`
    padding: 25px 0 15px;
  `}
`;

const CustomClose = styled(CloseBtn)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity .2s ease;
  opacity: ${(props) => (props.hide ? "0" : "1")};
`;

const CustomGrid = styled(Grid)`
  display: block;
  ${media.large`
    display: grid;
  `}
`;

const HeadCol = styled.div`
  ${media.large`
    grid-column: span 4;
  `}
`;

const BodyWrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.2s ease, opacity 0.2s ease;
`;

const BodyInner = styled.div`
  position: relative;
  padding: 10px 0 40px;

  ${media.large`
    padding: 50px 0 60px;
  `}
`;

const BodyProfileCol = styled.div`
  grid-column: 1 / span 4;
  ${media.large`
    grid-column: span 4;
    padding: 0 50px 0 0;
  `}
`;

const BodyProfileInner = styled.div`
  position: relative;

`;

const BodyLargeCol = styled.div`
  grid-column: 1 / span 6;

  ${media.large`
    grid-column: span 4;
  `}
`;

const BodySmallCol = styled.div`
  grid-column: 1 / span 6;

  ${media.large`
    grid-column: span 4;
  `}
`;

const Name = styled.div`
  ${fontstack.defaultVariable};
  ${type("body1")}
  font-variation-settings: "wght" 400;
  color: var(--theme-primary);
  transition: font-variation-settings 0.2s ease;
  margin: 0 0 15px;

  ${media.large`
    ${type("body2")}
    margin: 0;
  `}
`;

const MultipleRow = styled.div`
  display: flex;
`;

const Role = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);
  text-transform: uppercase;
  margin: 0 0 5px;

  ${media.large`
    ${type("subheaderB")}
    margin: 0;
  `}
`;

const Location = styled(Role)`
`;

const NumberedListCustom = styled(NumberedList)`
  & > h3 {
    margin: 0 0 10px;

    ${media.large`
      margin: 0 0 25px;
    `}
  }
`;

const SubHeader = styled.h3`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey);
  text-transform: uppercase;
  margin: 0 0 8px;

  ${media.large`
    ${type("subheaderB")}
    margin: 0 0 25px;
  `}
`;

const Description = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);
  letter-spacing: 0.015em;

  ${media.large`
    ${type("subheaderB")}
  `}
`;

const AccreditationsWrapper = styled.div`
  margin: 0 0 40px;
`;

const AccreditationsList = styled.ol`
  margin: 0;
  padding: 0;
  counter-reset: item;
  list-style-type: none;
`;

const Accreditation = styled.li`
  position: relative;
  display: flex;
  ${fontstack.secondary}
  ${type("subheaderB")}
  color: var(--brand-grey2);

  &:not(:last-child) {
    margin: 0 0 22px;
  }

  &:before {
    content: counter(item) " ";
    counter-increment: item;
    width: 13px;
    display: block;
    vertical-align: super;
    font-size: 0.8em;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  position: relative;
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);

  ${media.large`
    ${type("subheaderB")}
  `}
`;

const ContactList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Contact = styled.li`
  position: relative;
  margin: 0 0 6px;

  ${media.large`
    margin: 0;
  `}
`;

const ExtneralLink = styled.a`
  color: inherit;
`;

const AtSymbol = styled.div`
  color: var(--brand-primary);
  margin: 0 4px 0 0;
`;

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid var(--brand-grey3);
  padding: 0 15px;
  ${media.large`
    padding: 0;
  `}

  & ${BodyWrapper} {
    max-height: ${(props) => (props.open ? "1000px" : "0")};
    opacity: ${(props) => (props.open ? "1" : "0")};
  }

  &:hover ${Name} {
    font-variation-settings: "wght" 500;
  }

  & ${Name} {
    font-variation-settings: "wght" ${(props) => (props.open ? "500" : "400")};
  }
`;

const OrganizationItem = React.forwardRef(
  ({ node, id, active, toggleEvent, ...rest }, ref) => {
    const {
      name,
      roles,
      locations,
      subTitle,
      description,
      accreditations,
      contactList,
      image,
    } = node;

    const onHeadClicked = () => {
      if (active) {
        toggleEvent(null);
      } else {
        toggleEvent(id);
      }
    };

    return (
      <Wrapper open={active} ref={ref} {...rest}>
        <HeadWrapper onClick={onHeadClicked}>
          <CustomGrid>
            <HeadCol>
              <Name>{name}</Name>
            </HeadCol>
            <HeadCol>
              {roles &&
                roles.map((role, i) => {
                  return (
                    <Role key={i}>
                      {role.label}
                      {i !== roles.length - 1 ? <>/</> : ""}
                    </Role>
                  );
                })}
            </HeadCol>
            <HeadCol>
              <MultipleRow>
                {locations &&
                  locations.map((location, i) => {
                    return (
                      <Location key={i}>
                        {location.label}
                        {i !== locations.length - 1 ? <>,&nbsp;</> : ""}
                      </Location>
                    );
                  })}
              </MultipleRow>
            </HeadCol>
          </CustomGrid>
          <CustomClose hide={!active} noLabel/>
        </HeadWrapper>
        <BodyWrapper>
          <BodyInner>
            <Grid>
              <BodyProfileCol>
                {image && (
                  <LazyBlurImage
                    src={`${image.asset.url}?w=1000&q=80`}
                    placeholder={{
                      src: image.asset.metadata.lqip,
                      width: 100,
                      height: 100,
                    }}
                  />
                )}
              </BodyProfileCol>
              <BodyLargeCol>
                <SubHeader>
                  {subTitle && <PortableText blocks={subTitle} />}
                </SubHeader>
                <Description>
                  {description && <PortableText blocks={description} />}
                </Description>
              </BodyLargeCol>
              <BodySmallCol>
                {accreditations && accreditations.length > 0 ? (
                  <NumberedListCustom label="accreditations:">
                    {accreditations &&
                      accreditations.map((accreditation, i) => (
                        <NumberedItem key={i}>{accreditation}</NumberedItem>
                      ))}
                  </NumberedListCustom>
                ) : null}
                <ContactWrapper>
                  <AtSymbol>@</AtSymbol>
                  <ContactList>
                    {contactList &&
                      contactList.map((contact, i) => (
                        <Contact key={i}>
                          <ExtneralLink href={contact.url} target="_blank">
                            {contact.label}
                          </ExtneralLink>
                        </Contact>
                      ))}
                  </ContactList>
                </ContactWrapper>
              </BodySmallCol>
            </Grid>
          </BodyInner>
        </BodyWrapper>
      </Wrapper>
    )
});

export default OrganizationItem;
