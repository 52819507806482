import React, { useState } from "react";
import styled from "styled-components";
import { Arrow } from "../Iconography/Iconography";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";

const IconArrow = styled(Arrow)`
  width: 13px;
  margin: 0 10px 0 0;
  & > svg {
    fill: var(--brand-grey2);
    transform: ${(props) =>
      props.direction === "desc" ? "rotate(-90deg)" : "rotate(90deg)"};
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  ${fontstack.secondary}
  ${type("subheaderB")}
  line-height: 16px;
  color: ${(props) =>
    props.active ? "var(--brand-primary)" : "var(--brand-grey2)"};
  text-transform: uppercase;
  transition: color 0.2s ease;
  cursor: pointer;
  user-select: none;

  & ${IconArrow} > svg {
    fill: ${(props) =>
      props.active ? "var(--brand-primary)" : "var(--brand-grey2)"};
  }

  &:hover {
    color: var(--brand-primary);
    ${IconArrow} > svg {
      fill: var(--brand-primary);
    }
  }
`;

export const SortButton = (props) => {
  const { children, active, direction, ...rest } = props;

  return <ButtonWrapper active={active} {...rest}><IconArrow direction={direction}/>{children}</ButtonWrapper>;
};
